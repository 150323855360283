<template>
  <div class="levelmange">
    <div class="_SelectHeader" style="padding: 15px 20px 0 20px">
      <p class="round"></p>
      <p>会员管理</p>
    </div>
    <div class="_SelectHeader" style="padding: 15px 20px">
      <div class="_Select">
        <span style="width: 80px">公司名称：</span>
        <el-select v-model="compId" clearable placeholder="请选择">
          <el-option
            v-for="item in compyList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="_Select">
        <span style="width: 80px">会员等级：</span>
        <el-select v-model="memberLevel" clearable placeholder="请选择">
          <el-option
            v-for="item in levelList"
            :key="item.memberLevel"
            :label="item.memberLevel"
            :value="item.memberLevel"
          >
          </el-option>
        </el-select>
      </div>
      <div class="_Select">
        <span style="width: 100px">创建时间：</span>
        <el-date-picker
          v-model="createDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择开始日期"
        >
        </el-date-picker>
      </div>
      <el-button icon="el-icon-search" @click="getTableData"></el-button>
      <el-button type="primary" icon="el-icon-document-add" @click="addItem"
        >新增</el-button
      >
    </div>

    <div class="_TableBox">
      <el-table
        :data="tableData.slice(start, end)"
        style="width: 100%"
        :height="elementHeight"
        border
        @row-dblclick="toDetailPage"
        stripe
        highlight-current-row
      >
        <el-table-column prop="compNo" label="会员编号" width="300">
        </el-table-column>
        <el-table-column prop="compName" label="公司名称"> </el-table-column>
        <el-table-column prop="dutyUserName" label="负责人姓名" width="100">
        </el-table-column>
        <el-table-column prop="memberLevel" label="会员等级" width="130">
        </el-table-column>
        <el-table-column prop="amount" label="消费金额（元）" width="120">
        </el-table-column>
        <el-table-column prop="integral" label="积分" width="120">
        </el-table-column>
        <el-table-column prop="equityState" label="会员权益" width="150">
        </el-table-column>
        <el-table-column prop="saveTime" label="保存时间" width="130">
          <template slot-scope="scope">
            <span>{{
              scope.row.saveTime ? scope.row.saveTime.split("T")[0] : ""
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作">
          <template>
            <i class="el-icon-edit"></i>
            <i class="el-icon-delete"></i>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      ></el-pagination>
    </div>
    <el-dialog
      title="新增跟进记录"
      class="recordDialog"
      :visible.sync="showDialog"
      width="25%"
    >
      <el-form :model="addForm" ref="ruleForm" :rules="rules" label-width="80px">
        <el-form-item label="公司名称" prop="compId2">
          <el-select v-model="addForm.compId2" clearable placeholder="请选择">
            <el-option
              v-for="item in compyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="摘要">
          <el-input v-model="addForm.abstract"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="addForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="addForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="消费类型" prop="consumeType">
          <el-select v-model="addForm.consumeType" clearable placeholder="请选择">
            <el-option
              v-for="item in [
                { name: '消费', value: 0 },
                {
                  name: '赠送',
                  value: 1,
                },
              ]"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="reqData">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from "../../api/http";
export default {
  data() {
    return {
      elementHeight: 0,
      tableData: [],
      compyList: [],
      levelList: [],
      compId: "",
      memberLevel: "",
      createDate: "",
      start: 0,
      end: 10,
      currentPage: 1,
      curSize: 10,
      pageSize: 1,
      // 新增
      showDialog: false,
      addForm:{
        compId2: null,
        abstract: "",
        amount: "",
        remark: "",
        consumeType: null,
      },
      rules: {
        compId2: [
          { required: true, message: '请选择公司名称', trigger: 'change' },
        ],
        consumeType:[
          { required: true, message: '请选择消费类型', trigger: 'change' },
        ]
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "LevelDetail") {
      to.meta.ifDoFresh = true;
    }
    next();
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false;
      this.getElementHeight();
      this.getTableData();
      this.getMemberLevel();
      this.getCompny();
    }
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 90 + 120);
      });
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    // 表单重置
    reset() {
      this.addForm = {
        compId2: null,
        abstract: '',
        amount: '',
        remark: '',
        consumeType: null,
      };
      this.$nextTick(() => {
        this.$refs["ruleForm"].resetFields();
      });
    },
    toDetailPage(row) {
      console.log(row)
      this.$router.push(
        "/level-detail?integralItem=" + JSON.stringify(row)
      );
    },
    getCompny() {
      get("/api/Company/GetOrganize").then((resp) => {
        if (resp.code == 200) {
          this.compyList = resp.data;
        }
      });
    },
    getMemberLevel() {
      get("/api/MemberLevel").then((resp) => {
        if (resp.code == 200) {
          this.levelList = resp.data;
        }
      });
    },
    getTableData() {
      get(
        "/api/MemberConsume/GetMemberIntegral?CompId=" +
          this.compId +
          "&MemberLevel=" +
          this.memberLevel +
          "&CreateDate=" +
          this.createDate
      ).then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data;
        }
      });
    },
    addItem() {
      this.showDialog = true;
      this.reset()
    },
    reqData() {
      this.$refs["ruleForm"].validate((valid) => {
        if(valid){
          post("/api/MemberConsume", {
            id: 0,
            compId: this.addForm.compId2,
            abstract: this.addForm.abstract,
            amount: this.addForm.amount,
            consumeType: this.addForm.consumeType,
            remark: this.addForm.remark,
            dutyUserId: this.$store.state.userInfo.id,
            dutyUserName: this.$store.state.userInfo.userName,
          })
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "新增成功",
                });
                this.showDialog = false;
                this.getTableData();
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("新增失败");
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.levelmange {
  .round {
    width: 6.5px;
    height: 6px;
    border-radius: 50px;
    border: 2px solid #4f89ff;
    margin-right: 10px;
  }
  ._SelectHeader {
    background-color: white;
    ._Select {
      margin-right: 20px;
    }
  }
  ._TableBox {
    margin-top: 10px;
    border: none !important;
    padding: 10px;
  }
  .recordDialog {
    .el-form-item {
      margin-bottom: 10px !important;
    }
    .el-input {
      width: 200px !important;
    }
  }
}
</style>